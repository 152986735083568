<template>
  <section class="pagination-wrapper">
    <el-pagination
      background
      :current-page="currentPage"
      :page-sizes="pageSizes"
      :page-size="pageSize"
      layout="total,prev, pager, next ,sizes"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </section>
</template>

<script>
export default {
  name: 'IPagination',
  props: {
    hideOnSinglePage: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 1,
      required: true,
    },
    pageSize: {
      type: Number,
      default: 10,
      required: true,
    },
    layout: {
      type: String,
      default: '',
      required: false,
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 30, 50, 100],
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      radio: -1,
    }
  },
  methods: {
    handleSizeChange(e) {
      this.$emit('size-change', e)
    },
    handleCurrentChange(current) {
      this.$emit('current-change', current)
    },
  },
}
</script>

<style scoped lang="less">
@import './ipagination.less';
</style>
