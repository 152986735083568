import { unionBy, remove } from 'lodash'
import { postAction, deleteAction } from '@/api/manage'

const TableListMixin = {
  data() {
    return {
      modalForm: '',
      iPagination: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      queryParams: {},
      searchForm: {},
      dataSource: [],
      checkedRows: [],
      loading: false,
      url: {},
    }
  },
  created() {
    const data = this.$route.query
    if (data) {
      this.searchForm = {
        ...data,
      }
    }
    this.loadData()
  },
  methods: {
    getQueryParams() {
      return {
        pageNumber: this.iPagination.currentPage,
        pageSize: this.iPagination.pageSize,
        data: {
          ...this.searchForm,
          ...this.queryParams,
        },
      }
    },
    // 请求列表
    async loadData() {
      try {
        this.loading = true
        const params = this.getQueryParams()
        const res = await postAction(this.url.list, params)
        if (res.success === false) {
          this.$message.error(res.description)
        }
        this.loading = false
        this.iPagination.total = parseInt(res.total, 10)
        this.dataSource = res.content
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 页码发生变化
    handlePageChange(current) {
      this.iPagination.currentPage = current
      this.loadData()
    },
    // 每页显示的条数发生的变化
    handleSizeChange(pageSize) {
      this.iPagination.pageSize = pageSize
      this.loadData()
    },
    // 搜索
    handleSearch(data) {
      console.log(data)
      this.searchForm = data
      this.loadData()
    },
    // 重置
    handleRest(data) {
      this.searchForm = data
      this.iPagination.currentPage = 1
      this.iPagination.pageSize = 10
      this.loadData()
    },
    // 新增
    handleAdd() {
      this.$refs.modalForm.isEdit = false
      this.$refs.modalForm.title = '新增'
      this.$refs.modalForm.add()
    },
    // 编辑
    handleEdit(row) {
      this.$refs.modalForm.isEdit = true
      this.$refs.modalForm.title = '编辑'
      this.$refs.modalForm.edit(row)
    },
    // 单个删除 删除
    async handleSingleDelete(params, options = {}) {
      try {
        const res = await deleteAction(this.url.singleDeleteUrl, params, options)
        if (res.success === false) {
          this.$message.error(res.description)
          return
        }
        await this.loadData()
        this.$refs[this.tableRef].setAllTreeExpand(true)
        this.$message.success('删除成功')
      } catch (e) {
        console.log(e)
      }
    },
    // 多个删除
    async handleMultiDelete(params, options = {}) {
      try {
        await deleteAction(this.url.multiDeleteUrl, params, options)
      } catch (e) {}
    },

    // 单个启用
    async handleSingleSetIsDisable(data, type = 1) {
      try {
        const res = await postAction(this.url.status, data)
        if (type === 2) {
          // 多选
          this.checkedRows = []
          this.tableOptions.checkboxConfig.checkRowKeys = []
          this.$refs[this.tableRef].clearCheckboxRow()
        }
        if (res.success === false) {
          this.$message.error(res.description)
          return
        }
        this.$message({
          type: 'success',
          message: '操作成功!',
        })
        await this.loadData()
      } catch (err) {
        console.log(err)
      }
    },

    // 新增编辑ok
    modalFormOk(data) {
      if (!this.modalForm) {
        console.error('this.modalForm不存在')
      }
      this.$refs[this.modalForm].visible = false
      this.loadData()
    },

    // checkbox change
    handleCheckboxChange({ records, reserves, indeterminates, checked, row, rowIndex }) {
      if (checked) {
        this.checkedRows = unionBy([...this.checkedRows, row])
      } else {
        this.checkedRows = remove(this.checkedRows, (item) => item[this.rowId] !== row[this.rowId])
      }

      this.tableOptions.checkboxConfig.checkRowKeys = this.checkedRows.map((item) => item[this.rowId])
    },
    handleCheckboxAll({ records, reserves, indeterminates, checked, row, rowIndex }) {
      if (checked) {
        this.checkedRows = unionBy([...this.checkedRows, ...records])
      } else {
        this.dataSource.forEach((opt) => {
          this.checkedRows = remove(this.checkedRows, (item) => item[this.rowId] !== opt[this.rowId])
        })
      }
      this.tableOptions.checkboxConfig.checkRowKeys = this.checkedRows.map((item) => item[this.rowId])
    },
  },
}

export default TableListMixin
